import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import thanks from './pngwing.com (5).png'
const RegistrationForm = () => {
  const [state, handleSubmit] = useForm("mdoqqnng");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (state.succeeded) {
    return (
      <section className="min-h-screen bg-gray-100 flex items-center justify-center py-12">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full mx-auto text-center">
          <img 
            src={thanks} 
            alt="Thank You" 
            className="w-full h-48 object-cover mb-4" 
          />
          <h2 className="text-3xl font-semibold text-green-600 mb-4">Thank You!</h2>
          <p className="text-lg text-gray-700 mb-4">
            We appreciate your interest .
          </p>
          <p className="text-lg text-gray-700">
            You will receive further information in your email shortly.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            Best Regards,<br/>
            Ekam Innocations
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="min-h-screen bg-gray-100 flex items-center justify-center py-12">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full mx-auto">
        <h2 className="text-3xl font-semibold text-center mb-6">Do Register Your Self For Next Updates !!!</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="contact">Contact Number</label>
            <input
              type="text"
              id="contact"
              name="contact"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              value={formData.contact}
              onChange={handleChange}
              required
            />
            <ValidationError prefix="Contact" field="contact" errors={state.errors} />
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 font-semibold mb-2" htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <ValidationError prefix="Message" field="message" errors={state.errors} />
          </div>
          
          <button 
            type="submit" 
            disabled={state.submitting}
             className="bg-indigo-600 text-white py-3 px-3"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default RegistrationForm;
