import React from 'react';
import particles from './confetti.gif'
import book from './bookl.jpg'
const Modal = ({ showModal, closeModal }) => {
  if (!showModal) return null;

  return (
    <div className="fixed  z-[9999] inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-md mx-auto p-4 relative">
        <button
          className="absolute top-0 z-[999] right-0 mt-2 mr-2 text-gray-500 hover:text-gray-700"
          onClick={closeModal}
        >
            
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="text-center h-[400px]">
          <img src={book}  alt="Book Launch Banner" className="w-full h-full object-cover relative mt-4" />
          <img src={particles}  alt="Confetti" className="w-full absolute z-30 top-0 h-48 object-cover" />
          <h2 className="text-2xl font-semibold mt-4">Welcome to the Book Launch of <span className="italic">I Matter</span></h2>
          <p className="mt-2 text-lg">By Karamjeet Singh Sodhi</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
