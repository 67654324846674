import React from 'react';
import img from './bookl.jpg'
const BookLaunch = () => {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      {/* Header Section */}
      <header className="bg-[#F6811E] text-white py-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">Ekam Innocations Presents</h1>
          <h2 className="text-2xl mt-4">Book Launch: <span className="italic">I Matter</span></h2>
          <p className="text-lg mt-2">By K.S. Sodhi</p>
        </div>
      </header>

      

      {/* Book Section */}
      <section className="bg-[#f67f1e37] py-12">
        <div className="container mx-auto px-4">
          <h3 className="text-3xl font-semibold text-center">About the   <span className="italic">I Matter</span></h3>
          <div className="mt-8 flex flex-col md:flex-row lg:flex-row items-center justify-center gap-3 text-center">
            <div>

              <p className="max-w-2xl mx-auto text-lg md:text-4xl">
                <span className="italic">I Matter</span> is a profound exploration of self-worth and personal growth. In this inspiring book, K.S. Sodhi delves into the journey of discovering one's true value and purpose in life.
              </p>
            </div>
            <div className="mt-8">
              <img
                src={img}
                alt="I Matter Book Cover"
                className="mx-auto shadow-lg rounded-lg w-64"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Author Section */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <h3 className="text-3xl font-semibold text-center">About the Author</h3>
          <div className="mt-8 flex flex-col md:flex-row items-center md:space-x-8">
            <div className="md:w-1/3 text-center">
              <img
                src="https://i.ibb.co/Cngg4gR/image.png"
                alt="K.S. Sodhi"
                className="mx-auto rounded-full w-48 h-48 shadow-lg"
              />
            </div>
            <div className="mt-8 md:mt-0 md:w-2/3">
        <p className="text-lg">
          Karamjeet Singh Sodhi, also known as K.S. Sodhi, is a distinguished author and motivational speaker. With a deep passion for empowering individuals, his work focuses on personal development and self-empowerment. His latest book, <span className="italic">I Matter</span>, is a testament to his dedication to helping others realize their potential.
        </p>
        <p className="text-lg mt-4">
          A certified master trainer and English lecturer with over 20 years of experience, Karamjeet excels in TESOL, workshops, and mentorship. He is notable for his significant contributions to curriculum development, online capacity building, and has received multiple awards for his innovative teaching materials.
        </p>
      </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-indigo-600 text-white py-12">
        <div className="container mx-auto px-4 text-center">
          <h3 className="text-3xl font-semibold">Join Us for the Launch</h3>
          <p className="mt-4 text-lg">
            Be a part of this exciting event and discover the transformative power of <span className="italic">I Matter</span>. Reserve your spot now!
          </p>

        </div>
      </section>
    </div>
  );
};

export default BookLaunch;
