import React from 'react';
import particles from './confetti.gif'
import add from './AdmissionFare.jpg'
import { Link } from 'react-router-dom';
const AdmissionFair = () => {
  return (
    <section className="min-h-screen mr-auto bg-gray-100 text-gray-800 py-12">
      
      <div className=' grids md:grid max-w-7xl mx-auto grid-cols-1 md:grid-cols-2 space-x-2 gap-3'>
        <div className=' w-full md:w-[500px]'>
          <img src={add} className='w-full h-full  object-scale-down' alt="" />
        </div>
        <div className='md:w-[600px]'>
        <h1 className="text-4xl font-bold mb-4">StartFly Consultant Presents</h1>
        <h2 className="text-3xl font-semibold mb-4">Admission Fair 2024</h2>
        <p className="text-lg max-w-2xl mx-auto mb-8">
          Join us for the Admission Fair 2024 where both national and international universities will be present. This is a golden opportunity for students to explore a wide range of educational options and get all their queries answered.
        </p>
        <Link to={"/Register-forms"} className="bg-indigo-600 text-white py-3 px-3">
          Register Now
        </Link>
        </div>
      </div>

    </section>
  );
};

export default AdmissionFair;
