import React from 'react'
import Teachheader from './Teachheader'
import Postions from './Postions'
import AllPostions from './AllPostions'
import Testimonial from './TestIn'

const TecchAll = () => {
  return (
    <div>
        <Teachheader/>
        <Postions/>
        <AllPostions/>
        <Testimonial/>
    </div>
  )
}

export default TecchAll