import React from 'react'

const Error404 = () => {
  return (
    <div className='errro'>Error404 <br/>
    Page Not Found
    </div>
  )
}

export default Error404